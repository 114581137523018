import request from '@/utils/request';

// 列表
export function List(params) {
    return request({
        url: '/admin/custom/info/page',
        method: 'GET',
        params
    });
}

// 保存
export function Save(data) {
    return request({
        url: '/admin/custom/info/save',
        method: 'POST',
        data
    });
}

// 更新
export function Update(data) {
    return request({
        url: '/admin/custom/info/update',
        method: 'POST',
        data
    });
}

// 详情
export function Detail(params) {
    return request({
        url: '/admin/custom/info/detail',
        method: 'GET',
        params
    });
}

// 修改状态
export function ChangeState(data) {
  return request({
    url: '/admin/custom/info/changeState',
    method: 'POST',
    data
  });
}

// 设置用户密码
export function ChangePwd(data) {
  return request({
    url: '/admin/custom/info/changePwd',
    method: 'POST',
    data
  });
}
