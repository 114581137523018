<template>
  <div class="page-cu-container">
    <div class="page-cu-main">
      <a-table :columns="columns" :data-source="list" rowKey="id" bordered :pagination="false">
        <template slot="languages" slot-scope="text, record">
          <span v-if="text == 'scc'">简体中文</span>
          <span v-if="text == 'tcc'">繁体中文</span>
          <span v-if="text == 'en'">英语</span>
        </template>
        <template slot="gender" slot-scope="text, record">
          <span v-if="text == '1'">男</span>
          <span v-if="text == '2'">女</span>
          <span v-if="text == '0'">未知</span>
        </template>
        <template slot="source" slot-scope="text, record">
          <span v-if="text == '1'">小程序</span>
          <span v-if="text == '2'">APP</span>
          <span v-if="text == '3'">后台添加</span>
        </template>
        <template slot="state" slot-scope="text, record">
          <a-tag color="green" v-if="text == 1">启用</a-tag>
          <a-tag color="red" v-if="text == 2">禁用</a-tag>
        </template>
        <template slot="distributor_level" slot-scope="text, record">
          <span v-if="text == 1">不是分销商</span>
          <span v-if="text == 2">个人分销商</span>
          <span v-if="text == 3">团队分销商</span>
        </template>
        <template slot="action" slot-scope="text, record">
          <a-dropdown>
            <a class="ant-dropdown-link" @click="e => e.preventDefault()">
              更多操作<a-icon type="down" />
            </a>
            <a-menu slot="overlay">
              <a-menu-item key="1">
                <a-button type="link" v-if="record['state'] == 1" @click="openChangeState(record.id, 2)" style="color: #f50" icon="minus-circle">禁用</a-button>
                <a-button type="link" v-if="record['state'] == 2" @click="openChangeState(record.id, 1)" style="color: green" icon="check-circle">启用</a-button>
              </a-menu-item>
              <a-menu-divider />
              <a-menu-item key="3">
                <a-button type="link" @click="openResetPwdModel(record.id)" style="color: orange" icon="lock">重置密码</a-button>
              </a-menu-item>
            </a-menu>
          </a-dropdown>
        </template>
      </a-table>
    </div>
    <div class="page-cu-pagination">
      <a-pagination :page-size.sync="page.pageSize" :total="page.totalRow" v-model="page.start" @change="changePage"/>
    </div>
    <div class="page-cu-pagination"></div>
    <a-modal title="重置密码" :destroyOnClose="true" :mask="false" :visible="resetPwdVisible" @ok="resetPwdActionData" ok-text="确认" cancel-text="取消" @cancel="cancelResetPwd">
      <a-form-model ref="resetFormModel" :rules="pwdModelRules" :model="pwdModel" layout="vertical" :label-col="{ span: 6 }" :wrapper-col="{ span: 16 }">
        <a-form-model-item label="新密码" prop="login_pwd">
          <a-input type="password" v-model="pwdModel.login_pwd" placeholder="请输入新密码"></a-input>
        </a-form-model-item>
        <a-form-model-item label="再次输入新密码" prop="login_pwd1">
          <a-input type="password" v-model="pwdModel.login_pwd1" placeholder="请再次输入新密码"></a-input>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
import * as Api from './api'

export default {
  name: "index",
  data() {
    return {
      formModel: {
        name: '',
        file_url: '',
        content: '',
        sort: ''
      },
      formModelRules: {
        name: [{required: true, message: '请输入分类名称', trigger: 'change'}],
        file_url: [{required: true, message: '请上传分类图片', trigger: 'change'}],
        sort: [{required: true, message: '请输入排序数', trigger: 'change'}],
      },
      columns: [
        {
          title: '昵称',
          dataIndex: 'nickName',
          width: 200
        },
        {
          title: '性别',
          dataIndex: 'gender',
          width: 100,
        },
        {
          title: '手机号',
          dataIndex: 'mobile',
          width: 200
        },
        {
          title: '登录帐号',
          dataIndex: 'login_account',
          width: 200
        },
        {
          title: '来源',
          dataIndex: 'source',
          scopedSlots: {customRender: 'source'},
          width: 100
        },
        {
          title: '语种',
          dataIndex: 'languages',
          scopedSlots: {customRender: 'languages'},
          width: 100
        },
        {
          title: '分销商级别',
          dataIndex: 'distributor_level',
          scopedSlots: {customRender: 'distributor_level'},
          width: 150
        },
        {
          title: '状态',
          dataIndex: 'state',
          scopedSlots: {customRender: 'state'},
          width: 100
        },
        {
          title: '注册时间',
          dataIndex: 'create_time',
        },
        {
          title: '操作',
          width: 150,
          scopedSlots: {customRender: 'action'},
        },
      ],
      page: {
        start: 1,
        totalRow: 0,
        pageSize: 0,
        limit: 20
      },
      list: [],
      actionVisible: false,
      record: '',
      submitFlag: true,
      pwdModel: {
        id: '',
        login_pwd: '',
        login_pwd1: '',
      },
      pwdModelRules: {
        login_pwd: [{ required: true, message: '请输入新密码', trigger: 'change' }],
        login_pwd1: [{ required: true, message: '请再次输入新密码', trigger: 'change' }],
      },
      resetPwdVisible: false,
    }
  },
  mounted() {
    this.getList();

  },
  methods: {
    /**
     * 添加，更新
     *
     */
    actionData() {
      if (this.submitFlag) {
        this.$refs.formModel.validate(async valid => {
          if (valid) {
            let res = null;
            this.submitFlag = false;
            if (this.formModel['id']) {
              res = await Api.Update(this.formModel);
            } else {
              res = await Api.Save(this.formModel);
            }

            if (res && res['code'] == '0') {
              this.$message.success(res.message);
              this.actionVisible = false;
              this.getList();
            } else {
              this.$message.error(res.message);
            }
            this.submitFlag = true;
          }
        });
      }
    },
    /*
    * 表单重置
    **/
    resetFormModel() {
      for (let key in this.formModel) {
        this.formModel[key] = '';
      }
    },
    // 菜单选择
    handlerCheck(n, e) {
      this.checkedKeys = {checked: n, halfChecked: e.halfCheckedKeys};
    },
    // 打开添加角色窗
    addAction() {
      this.resetFormModel();
      this.actionVisible = true;
    },
    // 关闭窗口
    cancel() {
      this.actionVisible = false;
    },

    // 删除确认
    async delConfirm(id, state) {
      let that = this;
      this.$confirm({
        title: '信息提示',
        okType: 'danger',
        okText: '确定',
        cancelText: '取消',
        content: '您确定要删除吗?',
        async onOk() {
          let res = await Api.Delete({id: id})
          if (res && res.code == '0') {
            that.$message.success(res.message);
            that.getList();
          } else {
            that.$message.error(res.message);
          }
        },
        onCancel() {
        },
      });
    },
    // 列表
    async getList() {
      let res = await Api.List({
        start: this.page.start,
        limit: this.page.limit,
      })
      this.page.totalRow = res.page.totalRow;
      this.page.pageSize = res.page.pageSize;
      this.list = res.page.list;
    },
    changePage(currentPage) {
      this.page.start = currentPage;
      this.getList();
    },
    // 打开编辑框
    async openEditDialog(id) {
      this.resetFormModel()
      if (this.submitFlag) {
        this.submitFlag = false;
        let res = await Api.Detail({id: id});
        if (res && res['code'] == '0') {
          this.formModel = Object.assign({}, res['dctionaries']);
          this.actionVisible = true;
        }
        this.$message.success(res.message);
        this.submitFlag = true;

      }
    },
    openResetPwdModel(id) {
      this.pwdModel.id = id;
      this.resetPwdVisible = true;
    },
    resetPwdActionData() {
      this.$refs.resetFormModel.validate(async valid => {
        if (valid) {
          let res = await Api.ChangePwd(this.pwdModel);
          if (res && res['code'] == '0') {
            this.$message.success(res.message);
            this.resetPwdVisible = false;
            this.getList();
          } else {
            this.$message.error(res.message);
          }
        }
      });
    },
    cancelResetPwd() {
      this.resetPwdVisible = false;
    },
    openChangeState(id, state) {
      let icon = state == 2 ? 'danger' : 'success';
      let text = state == 2 ? '您确定要禁用吗？' : '您确定要启用吗';
      let that = this;
      this.$confirm({
        title: "信息提示",
        okType: icon,
        okText: "确定",
        cancelText: "取消",
        content: text,
        async onOk() {
          if (that.submitFlag) {
            that.submitFlag = false;
            let res = await Api.ChangeState({ id: id, state: state });
            if (res && res["code"] == "0") {
              that.getList();
              that.$message.success(res.message);
            } else {
              that.$message.error(res.message);
            }
            that.submitFlag = true;
          }
        },
        onCancel() {},
      });
    }
  },
}
</script>

<style scoped>

</style>
